.home-bk {
  background-image: url(assets/sky-scrapers.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  filter: brightness(0.9);
  position: fixed;
}

.home-bk-dark {
  background-image: url(assets/neon-dark.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  filter: brightness(1.5);
  position: fixed;
}

pre {
  background: #e8e8e8;
  padding: 5px 10px;
  border-radius: 5px;
  color: rebeccapurple;
  display: inline-flex;
}
