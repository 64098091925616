.link {
  text-decoration: none;
  color: unset;
}

.link-text {
  padding: 0px 15px 0px 5px;
}

.link-container {
  padding: 20px;
}

.icon {
  font-size: 15px !important;
}
